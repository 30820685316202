import React from "react";
import { Container, Grid, Paper, Box } from "@mui/material";
import zonsondergang from '../assets/img/zonsonderganghuizen.jpg';
import kraan from '../assets/img/kraanhuizen.jpg';
import boring from '../assets/img/boringschepdaal.jpg';
import MediaCard from "./MediaCard";

function Properties() {




    return (
        <Box className="properties">
            <Container className="propertiescontainer">
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        <MediaCard myImage={zonsondergang} myTitle="Persoonlijk" myContent="Persoonlijke aanpak"/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <MediaCard myImage={kraan} myTitle="Kwaliteit" myContent="Kwaliteitsvolle aanpak"/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <MediaCard myImage={boring} myTitle="Open Communicatie" myContent="Steeds persoonlijke en open communicatie"/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Properties