import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './__tests__/reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
let theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#424242',
    },
  },
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    }
  }
});
theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
