import React, { useRef, useState } from "react"
import { Box, Button, Container, Fade, Paper, Typography } from "@mui/material"
import { useIsVisible } from "../hooks/UseIsVisible";

function CallToAction() {

    const ref = useRef();
    const isVisible = useIsVisible(ref);

    return (
        <Box className="calltoaction">
            
            <Container>
                <Fade in={isVisible}>
                <Paper elevation={10} className="calltoactionpaper" ref={ref}>
                    <Box className="calltoactiontext">
                        <Typography variant="h3">Werfcoördinatie met persoonlijke aanpak</Typography>
                        <Typography variant="body1">Wij streven naar een <b>kwaliteitsvolle</b> en <b>persoonlijke</b> aanpak</Typography>

                        {/* <p>Wij streven naar een optimale werfcoördinatie tijdens al onze renovatieprojecten. We vormen het aanspreekpunt van de architect, de ploegbaas en de particulier. Alle werken worden sterk op elkaar afgestemd. Onze strikte planning resulteert in een efficiënte uitvoering en zorgt ervoor dat het budget onder controle blijft.</p> */}
                    </Box>
                </Paper>
                </Fade>
            </Container>
            
        </Box>
    )
}
export default CallToAction