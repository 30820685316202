import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';
import { useIsVisible } from "../hooks/UseIsVisible";

export default function MediaCard( {myImage, myTitle, myContent}) {

  const ref = React.useRef();
  const isVisible = useIsVisible(ref);

  return (
    <Fade in={isVisible}>
          <Card variant='outlined' className='mediacard' style={{ border: '10px solid white'}} ref={ref}>
      <CardMedia component="img"
        sx={{ height: 140 }}
        image={myImage}
        title={myTitle}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {myTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {myContent}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </Fade>

  );
}