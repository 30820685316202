import React from "react"
import { Box, IconButton, Typography } from "@mui/material"
import BusinessIcon from '@mui/icons-material/Business'

function Footer() {
    return (
        <Box className="footer">
            <Typography variant="body2" component="h6" align="right" color='text.secondary'>
                Build by Bavo
                <IconButton
                    size="small"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    sx={{ ml: 2 }}
                >
                    <BusinessIcon />
                </IconButton>
            </Typography>
            <Typography variant="body2" component="h6" align="right" color='text.secondary'>
                BTW: XXX.XXX.XXX
            </Typography>
            <Typography variant="body2" component="h6" align="right" color='text.secondary'>
                build@bavo.be
            </Typography>
            <Typography variant="body2" component="h6" align="right" color='text.secondary'>
                Brusselstraat 1000 Brussel
            </Typography>
        </Box>
    )
}
export default Footer