import BasicAppBar from './components/BasicAppbar';
import './assets/css/App.css';
import Banner from './components/Banner';
import Footer from './components/Footer';
import Services from './components/Services';
import CallToAction from './components/CallToAction';
import Stack from '@mui/material/Stack'
import Home from './pages/Home'
import Privacy from './pages/Privacy'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './hooks/ScrollToTop';


function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy' element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
