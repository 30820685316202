import BasicAppBar from '../components/BasicAppbar';
import '../assets/css/App.css';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import Services from '../components/Services';
import CallToAction from '../components/CallToAction';
import SimpleBottomNavigation from '../components/SimpleBottomNavigation';
import Stack from '@mui/material/Stack'
import Navbar from '../components/Navbar';
import Properties from '../components/Properties';


function Home() {

  return (
    <div className="App">
      {/* <Banner></Banner> */}
      <Stack spacing={0}>
        <Navbar />
        <Banner />
        <CallToAction />
        <Properties />
        <Footer />
        <SimpleBottomNavigation/>
      </Stack>

    </div>
  );
}

export default Home;