import BasicAppBar from '../components/BasicAppbar';
import '../assets/css/App.css';
import Footer from '../components/Footer';
import Stack from '@mui/material/Stack'
import SimpleBottomNavigation from '../components/SimpleBottomNavigation';
import Navbar from '../components/Navbar';
import PrivacyContent from '../components/PrivacyContent';

function Privacy() {

    return (
        <div className="App">
            <Stack spacing={2}>
                <Navbar />
                <PrivacyContent />
                <SimpleBottomNavigation/>
            </Stack>

        </div>
    );
}

export default Privacy;