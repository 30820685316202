import React from "react";
import { Container, Grid, Paper, Box } from "@mui/material";
import zonsondergang from '../assets/img/zonsonderganghuizen.jpg';
import kraan from '../assets/img/kraanhuizen.jpg';
import boring from '../assets/img/boringschepdaal.jpg';

function Services() {




    return (
        <Box className="services">
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        <Paper className="servicespaper">
                            <img src={zonsondergang} alt="zonsondergang met huizen" className="serviceimage"></img>
                            <h2>Werfcoördinatie uitbesteden</h2>
                            <p>Wij beschikken over een ruime ervaring in de coördinatie van grote projecten. Onze nauwkeurige projectopvolging van renovaties heeft haar succes dus reeds bewezen. Uw renovatieproject in de provincie Antwerpen of de Kempen efficiënter laten verlopen? We nemen de coördinatie in handen.</p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper className="servicespaper">
                            <img src={kraan} alt="zonsondergang met huizen" className="serviceimage"></img>
                            <h2>Onze werfcoördinatie neemt zorgen weg</h2>
                            <p>Een groot renovatieproject brengt veel werk met zich mee. Dankzij onze ervaring in de coördinatie van verbouwingswerken zijn wij in staat om dit zo efficiënt mogelijk te laten verlopen. We zorgen voor een strikte tijdsplanning en duidelijke rolverdeling. Het vooropgestelde budget wordt constant in het oog gehouden. Zoekt u een aannemer voor uw renovatieproject en is een sterke planning en werfcoördinatie van groot belang? Wij zetten tijd en budget zo efficiënt mogelijk in.</p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper className="servicespaper">
                            <img src={boring} alt="zonsondergang met huizen" className="serviceimage"></img>
                            <h2>Werfcoördinatie met open communicatie</h2>
                            <p>We informeren u op elk ogenblik over de stand van zaken. Tijdens de opvolging van uw renovatieproject weet u op elk ogenblik welke werken reeds afgerond zijn en wat er verder in de planning staat. Dankzij onze werfcoördinatie kunnen we u steeds een volledig beeld verschaffen over de verschillende onderdelen van uw renovatieproject. Onze vorderingsstaten geven elk detail weer. Een aannemer met een open communicatie voor uw renovatieproject? Denk dan vooral aan ons!</p>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Services