import React from "react"
import { Box, Container, Typography, Button, Zoom, Fade } from "@mui/material"
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton';

function openEmailClient() {
    window.open('mailto:build@bavo.be?subject=Werfleider gezocht&body=Body%20goes%20here')
}

function Banner() {
    return (
        <Box className="banner">

            <Container className="bannercontainer">
                <Fade in={true}>
                    <Typography variant="h1" color="white" align="center">Renovatie zonder zorgen</Typography>
                </Fade>

                <Fade in={true} style={{transitionDelay: '500ms' }}>
                    <Box>
                        <Typography align="center">
                            <Button variant="contained" onClick={openEmailClient} sx={{ margin: "5%" }}>Contacteer ons!</Button>
                        </Typography>
                        <Typography variant="overline" display="block" color="white" align="center">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <BusinessIcon />
                            </IconButton>
                            Build by BAVO
                        </Typography>
                    </Box>
                </Fade>

            </Container>

        </Box>
    )
}
export default Banner