import React from "react"
import { Box, Container, List, ListItem, Stack, Typography } from "@mui/material"

function PrivacyContent() {
    return (
        <Box className="privacyContent">
            <Container>
                <Stack spacing={5}>
                    <Box>
                        <Typography variant="h1">Privacy Policy</Typography>
                        <Typography variant="subtitle">Bavo makes sure to process personal data with the utmost care. Personal data is processed and secured in accordance with the General Data Protection Regulation (GDPR).</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h2">What personal data we process</Typography>
                        <Typography variant="subtitle2">Personal data is any information that relates to an identified or identifiable living, natural person. This can for instance be a name, identification number, address, or e-mail address. We collect, process, and store the following personal data:</Typography>
                        <List>
                            <ListItem>Information about your visit to our website such as IP address, geographical location, browser, length of visit, and which pages you viewed.</ListItem>
                            <ListItem>Names, positions, e-mail addresses, phone numbers of our external partners.</ListItem>
                            <ListItem>Names, addresses, e-mail addresses, phone numbers and information on CVs from job applicants.</ListItem>
                            <ListItem>E-mail addresses, names, phone numbers and any other information you actively share with Bavo.</ListItem>
                            <ListItem>Personal data obtained from third parties in the context of our services or in the context of a research project, such as but not limited to energy consumption data, energy production data, addresses.</ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Typography variant="h2">What we do with personal data</Typography>
                        <Typography variant="subtitle2">We do not process personal data without a legal basis nor beyond what is necessary in relation to the purpose for which the personal data is collected. We process personal data to:</Typography>
                        <List>
                            <ListItem>Conduct our services.</ListItem>
                            <ListItem>Analyse web data and log files.</ListItem>
                            <ListItem>Administer contracts.</ListItem>
                            <ListItem>Contact our network.</ListItem>
                            <ListItem>Organise events.</ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Typography variant="h2">How we secure personal data</Typography>
                        <Typography variant="subtitle2">We store your personal data on our own secured network. Only the Bavo staff has access to the personal data through a personal log-in to the Bavo network.</Typography>

                    </Box>
                    <Box>
                        <Typography variant="h2">How long we store personal data</Typography>
                        <Typography variant="subtitle2">We only keep your personal data for as long as is necessary to fulfil the purposes for which the personal data is collected. Once the personal data is no longer needed, it is permanently deleted. In the case of consent the personal data is stored until consent is withdrawn.</Typography>

                    </Box>

                    <Box>
                        <Typography variant="h2">Transfer of personal data to third parties</Typography>
                        <Typography variant="subtitle2">Bavo is the data controller of the personal data obtained. We do not sell your personal data to third parties. In order to conduct our services we sometimes work with partners who we may share personal data with, or obtain personal data from. We do not work with partners outside the EU/EEA. This means that our partners also process and secure personal data in accordance with the GDPR and have their own privacy policies. We do not share or receive information beyond what is necessary to fulfil the purposes for which the personal data is obtained or shared.</Typography>

                    </Box>

                    <Box>
                        <Typography variant="h2">Cookies</Typography>
                        <Typography variant="subtitle2">Cookies are short files containing an identifier which are stored on your device when you visit a website. We use cookies for the technical functioning of our website, to improve our website security and to analyse statistics. Bavo manages the website and places cookies. Bavo uses Google Analytics to track information about website use and analyse web data. The cookies are stored and tracked using Google Analytics. You can consult Google’s privacy policy at: http://www.google.com/privacypolicy.html.</Typography>

                    </Box>

                    <Box>
                        <Typography variant="h2">Job candidates</Typography>
                        <Typography variant="subtitle2">Bavo only uses personal data of job candidates for recruitment and selection purposes. We do not use more information than necessary to fulfil this task. This is limited to: </Typography>
                        <List>
                            <ListItem>Contacting you about your application or future vacancies.</ListItem>
                            <ListItem>Assessment of your application and processing the application procedure.</ListItem>
                            <ListItem>Contracting and onboarding.</ListItem>
                        </List>

                    </Box>


                    <Box>
                        <Typography variant="h2">Your rights</Typography>
                        <Typography variant="subtitle2">The GDPR entitles you to the following rights:</Typography>
                        <List>
                            <ListItem>Right to access: you have the right to access the personal data that we have about you.</ListItem>
                            <ListItem>Right to rectification: you have the right to rectify personal data that we have about you that is inaccurate.</ListItem>
                            <ListItem>Right to restriction: you have the right to ask us to limit how we process your personal data.</ListItem>
                            <ListItem>Right to erasure: you have the right to ask us to permanently delete your personal data.</ListItem>
                            <ListItem>Right to data portability: you have the right to obtain the personal data we have about you in order to transmit the personal data to another controller.</ListItem>
                            <ListItem>Right to withdraw consent: if we process your personal data on basis of your consent, you can always withdraw this consent.</ListItem>
                        </List>

                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}
export default PrivacyContent